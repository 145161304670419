import * as types from "../actions/actionTypes";

const initialState = {
    loading: false,
    message: "",
    status: false,
    data: null,
    totalCount: 0,
}

export default function SearchTransactionReducer (state=initialState, action) {
    switch (action.type) {
        case types.SEARCH_TRANSACTION_FAILURE:
            return {
                ...state,
                message: action.message,
                loading: false
            }
        case types.SEARCH_TRANSACTION_LOADING:
            return {
                ...state,
                loading: true,
            }
        case types.SEARCH_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                data: action.data,
                status: action.status,
                totalCount: action.totalCount
            }
        case types.RESET_STATE:
            return state;
        default:
            return {
                ...state
            }
    }
}