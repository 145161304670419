import * as types from "../actions/actionTypes";

const initialState = {
    loading: false,
    message: "",
    status: false,
    data: null
}

export default function LogTransactionReducer (state = initialState, action) {
    switch (action.type) {
        case types.LOG_TRANSACTION_FAILURE:
            return {
                ...state,
                loading: false,
                message: action.message
            }
        case types.LOG_TRANSACTION_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.LOG_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
                status: action.status,
                message: action.message
            }
        case types.RESET_STATE:
            return state
        default:
            return {
                ...state
            }
    }
}