import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { utilReducer } from "./utilReducer";
import { modalsReducer } from "./modalsReducer";
import CreateTerminalReducer from "./createTerminalReducer";
import EditTerminalReducer from "./editTerminalReducer";
import RetrieveTerminalReducer from "./retrieveMerchantTerminalsReducer";
import SingleTerminalReducer from "./singleTerminalReducer";
import SearchTransactionReducer from "./searchTransactionReducer";
import LogTransactionReducer from "./logTransactionReducer";
import SettlementStatusReducer from "./settlementStatusReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  util: utilReducer,
  modals: modalsReducer,
  createTerminal: CreateTerminalReducer,
  editTerminal: EditTerminalReducer,
  retrieveTerminal: RetrieveTerminalReducer,
  singleTerminal: SingleTerminalReducer,
  searchTransaction: SearchTransactionReducer,
  logTransaction: LogTransactionReducer,
  settlementStatus: SettlementStatusReducer
});

export default rootReducer;
