import * as types from "../actions/actionTypes";
const initialState = {
  isLoggedIn: false,
  loading: false,
  status: false,
  message: "",
  token: "",
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_INPROGRESS:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        token: action.token
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        status: action.status,
        message: action.message,
      };
    case types.RESET_LOGIN_STATE:
      return state;
    case types.SIGN_OUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
