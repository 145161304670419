export const urls = {
  baseUrl: "https://partners.kuda.com/api/Terminal",
  login: "https://partners.kuda.com/api/Auth/authenticate",
  createTerminal: "/CreateTerminal",
  createBulkTerminal: "/BulkCreateTerminal",
  editTerminal: "/EditTerminal",
  retrieveMerchantTerminals: "/SearchMerchantTerminals",
  singleTerminal: "/SingleTerminal",
  searchTransaction: "/SearchTransaction",
  logTransaction: "/LogTransaction",
  settlementStatus: "/SettlementStatus",
  updateTerminalStatus: "/UpdateTerminalStatus",
};
