// onboarding Actions

export const LOGIN_INPROGRESS = "LOGIN_INPROGRESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";
export const SIGN_OUT = "SIGN_OUT";

export const MENU_TOGGLE = "MENU_TOGGLE";


// MODAL_ACTIONS
export const OPEN_CONNECT_K4B_MODAL = "OPEN_CONNECT_K4B_MODAL";
export const OPEN_CONNECTING_K4B_MODAL = "OPEN_CONNECTING_K4B_MODAL";
export const OPEN_GO_LIVE_REQUEST = "OPEN_GO_LIVE_REQUEST";
export const OPEN_FEEDBACK_MODAL = "OPEN_FEEDBACK_MODAL";

export const RESET_STATE = "RESET_STATE"


export const CREATE_TERMINAL_LOADING = "CREATE_TERMINAL_LOADING";
export const CREATE_TERMINAL_FAILURE = "CREATE_TERMINAL_FAILURE";
export const CREATE_TERMINAL_SUCCESS = "CREATE_TERMINAL_SUCCESS";


export const CREATE_BULK_TERMINAL_LOADING = "CREATE_BULK_TERMINAL_LOADING";
export const CREATE_BULK_TERMINAL_FAILURE = "CREATE_BULK_TERMINAL_FAILURE";
export const CREATE_BULK_TERMINAL_SUCCESS = "CREATE_BULK_TERMINAL_SUCCESS";

export const EDIT_TERMINAL_LOADING = "EDIT_TERMINAL_LOADING";
export const EDIT_TERMINAL_FAILURE = "EDIT_TERMINAL_FAILURE";
export const EDIT_TERMINAL_SUCCESS = "EDIT_TERMINAL_SUCCESS";


export const UPDATE_TERMINAL_STATUS_LOADING = "UPDATE_TERMINAL_STATUS_LOADING";
export const UPDATE_TERMINAL_STATUS_FAILURE = "UPDATE_TERMINAL_STATUS_FAILURE";
export const UPDATE_TERMINAL_STATUS_SUCCESS = "UPDATE_TERMINAL_STATUS_SUCCESS";

export const RETRIEVE_TERMINAL_LOADING = "RETRIEVE_TERMINAL_LOADING";
export const RETRIEVE_TERMINAL_FAILURE = "RETRIEVE_TERMINAL_FAILURE";
export const RETRIEVE_TERMINAL_SUCCESS = "RETRIEVE_TERMINAL_SUCCESS";

export const SINGLE_TERMINAL_LOADING = "SINGLE_TERMINAL_LOADING";
export const SINGLE_TERMINAL_FAILURE = "SINGLE_TERMINAL_FAILURE";
export const SINGLE_TERMINAL_SUCCESS = "SINGLE_TERMINAL_SUCCESS";

export const SEARCH_TRANSACTION_LOADING = "SEARCH_TRANSACTION_LOADING";
export const SEARCH_TRANSACTION_FAILURE = "SEARCH_TRANSACTION_FAILURE";
export const SEARCH_TRANSACTION_SUCCESS = "SEARCH_TRANSACTION_SUCCESS";

export const LOG_TRANSACTION_LOADING = "LOG_TRANSACTION_LOADING";
export const LOG_TRANSACTION_FAILURE = "LOG_TRANSACTION_FAILURE";
export const LOG_TRANSACTION_SUCCESS = "LOG_TRANSACTION_SUCCESS";

export const SETTLEMENT_STATUS_LOADING = "SETTLEMENT_STATUS_LOADING";
export const SETTLEMENT_STATUS_FAILURE = "SETTLEMENT_STATUS_FAILURE";
export const SETTLEMENT_STATUS_SUCCESS = "SETTLEMENT_STATUS_SUCCESS";