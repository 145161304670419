import * as types from "../actions/actionTypes";

const initialState = {
  loading: false,
  message: "",
  status: false,
  errorCode: null,
  data: null,
  totalCount: 0,
};

export default function RetrieveTerminalReducer(state = initialState, action) {
  switch (action.type) {
    case types.RETRIEVE_TERMINAL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.RETRIEVE_TERMINAL_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.message,
        errorCode: action.errorCode,
      };
    case types.RETRIEVE_TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
        data: action.data,
        totalCount: action.totalCount,
      };
    case types.RESET_STATE:
      return state;
    default:
      return {
        ...state,
      };
  }
}
