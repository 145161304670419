import * as types from "../actions/actionTypes";

const initialState = {
    loading: false,
    message: "",
    status: false,
    data: null
}

export default function SingleTerminalReducer (state = initialState, action) {
    switch (action.type) {
        case types.SINGLE_TERMINAL_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.SINGLE_TERMINAL_FAILURE:
            return {
                ...state,
                loading: false,
                message: action.message
            }
        case types.SINGLE_TERMINAL_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
                data: action.data
            }
        case types.RESET_STATE:
            return state;
        default:
            return {
                ...state
            }
    }
}